import Button from 'src/components/ui/Button'
import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import RecallRockRider from './RecallRockRider'
import RecallCorda from './RecallCorda'
import RecallDomyosStrap from './RecallDomyosStrap'
import RecallRocky from './RecallRocky'
import RecallOnNight from './RecallOnNight'
import RecallRockriderHelmet from './RecallRockriderHelmet'
import '../styles.scss'

const Recall = () => {
  return (
    <>
      <section className="section-services recall_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar Recall-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- Recall Products-> */}
              <div className="services-content px-3">
                <div className="section__top align-center">
                  <RecallRockriderHelmet />
                  <RecallCorda />
                  <h1 className="text-blue italic text-2xl font-bold text-center uppercase">
                    Recall
                  </h1>
                  <Button className="bg-blue justify-center text-white text-xs my-0 m-auto flex items-center h-[30px] max-w-[234px] w-full hover:bg-darkBlue transition-all rounded-sm">
                    <a
                      className="consult-button text-xs font-bold uppercase"
                      href="/servicos/campanha-de-qualidade"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Campanha de qualidade
                    </a>
                  </Button>
                </div>

                <RecallRockRider />
                <RecallDomyosStrap />
                <RecallRocky />
                <RecallOnNight />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Recall
