import { Link } from 'gatsby'

const RecallRockriderHelmet = () => {
  return (
    <div
      id="recall-rockrider-helmet"
      className="recall section-anchor hr mt-[14px]"
    >
      <div className="av-row text-sm">
        <div className="av-col-xs-24">
          <div className="flex justify-between items-center">
            <h1 className="text-red-600 font-semibold text-xl">
              RECALL DE PRODUTO POR MOTIVOS DE SEGURANÇA
            </h1>
            <small className="min-w-fit">Data : 03/10/2024</small>
          </div>

          <h2 className="text-red-600 italic xl:text-lg lg:text-lg md:text-lg !text-[17px] font-semibold text-left">
            DECATHLON realiza o recall dos capacetes ROCKRIDER MTB EXPL500 ou
            ST500
          </h2>

          <div className="recall-products-items">
            <h3>
              Nome do produto e código do item: ROCKRIDER Helmet MTB EXPL500 ou
              ST500
            </h3>

            <ul className="flex flex-col sm:flex-row justify-center items-center my-4">
              <li className="w-[150px]">
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/recall-helmet2.png"
                  alt="Capacete EXPL500 ou ST500"
                />
              </li>
              <li className="w-[150px]">
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/recall-helmet4.png"
                  alt="Capacete EXPL500 ou ST500"
                />
              </li>
              <li className="w-[150px]">
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/recall-helmet3.png"
                  alt="Capacete EXPL500 ou ST500"
                />
              </li>

              <li className="w-[150px]">
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/recall-helmet5.png"
                  alt="Capacete EXPL500 ou ST500"
                />
              </li>
            </ul>

            <div className="recall-items-models">
              4243712 - MTB HELMET ST 500 TURQUOISE S, 2940783 - MTB HELMET ST
              500 TURQUOISE M, 5034306 - MTB HELMET EXPL 500 PASTEL BLUE S,
              5034307 - MTB HELMET EXPL 500 PASTEL BLUE M, 5034323 - MTB HELMET
              EXPL 500 FOREST GREEN M, 4229390 - MTB HELMET ST 500 ALL SEASONS
              L, 4229391 - MTB HELMET ST 500 ALL SEASONS M
            </div>

            <div className="text-lg my-4">
              Período de vendas impactado: de 02/07/2024 até 09/09/2024 Data de
              fabricação: 15/05/2024 a 17/05/2024
            </div>
          </div>

          <div className="items-with-problems mt-4">
            <h3 className="font-extrabold mb-4">
              Os lotes com desvio de conformidade são:
            </h3>

            <div className="flex flex-col sm:flex-row gap-4">
              <div className="recall-items-code max-w-[35%] flex gap-3 font-bold">
                <div>
                  KT24040006-15 KT24040006-16 KT24040006-17 KT24040018-14
                  KT24040018-16 KT24040018-17 KT24040034-10
                </div>
                <div>
                  KT24040034-2 KT24040034-4 KT24040034-8 KT24040045-10
                  KT24040045-8 KT24040045-9
                </div>
              </div>

              <div className="recall-helmet-details">
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/recall-helmet1.png"
                  alt="Capacete EXPL500 ou ST500"
                />
              </div>
            </div>
          </div>

          <div className="recall-more-info mt-4">
            <p>
              Onde posso encontrar a informação? Este número de lotte (iniciado
              por “KT”) está dentro do capacete, por baixo da espuma, localizado
              na frente, como ilustra a foto acima.
            </p>

            <h4 className="font-semibold text-red-600 text-lg p-2 border border-red-600 text-center mt-4 mb-2">
              Porque este produto é perigoso?
            </h4>

            <p>
              Uma peça de plástico na parte de trás do capacete que segura a
              tira de queixo pode quebrar. Se isso acontecer, durante uma queda,
              o capacete poderá sair do lugar devido ao impacto sofrido e,
              consequentemente, expondo o consumidor à proteção inadequada, com
              risco de traumas na cabeça e até morte.
            </p>

            <h4 className="font-semibold text-red-600 text-lg p-2 border border-red-600 text-center mt-4 mb-2">
              O que devo fazer?
            </h4>
            <p>
              Verifique o número do lote do seu capacete conforme indicado acima
              para checar se o mesmo pertence a essa demanda. Se assim for,
              <strong> pare de utilizar este capacete</strong> e devolva-o na
              loja DECATHLON mais próxima de você. Se você comprou seu capacete
              online, entre em contato com a equipe de relacionamento com o
              cliente.
            </p>
            <h4 className="font-semibold text-red-600 text-lg p-2 border border-red-600 text-center mt-4 mb-2">
              Alternativas disponíveis ao Consumidor
            </h4>
            <p>
              O produto envolvido neste recall pode ser devolvido imediatamente
              em loja para substituição, reembolso ou emissão de vale troca no
              valor da compra. O atendimento é gratuito (aproximadamente 15min).
            </p>
            <h4 className="font-semibold text-red-600 text-lg p-2 border border-red-600 text-center mt-4 mb-2">
              Contato
            </h4>
            <p>
              Nossa central de relacionamento com o cliente está disponível para
              esclarecer todas as suas dúvidas por meio dos seguintes canais:
              <Link
                target="_blank"
                className="ml-1 text-blue"
                to="https://www.decathlon.com.br/atendimento"
                rel="noreferrer"
              >
                https://www.decathlon.com.br/atendimento
              </Link>{' '}
              e
              <Link
                target="_blank"
                className="ml-1 text-blue"
                to="mailto:atendimento@decathlon.com"
                rel="noreferrer"
              >
                atendimento@decathlon.com
              </Link>{' '}
              <br /> Agradecemos a sua compreensão e pedimos desculpas por
              qualquer inconveniente causado.
            </p>
          </div>
          <div className="recall-video w-full flex justify-center items-center my-4">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/9iXB8bbcWzY?si=lUPhjOqeQl_QcGun"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>

          <div className="grid grid-cols-4 mt-20" />
        </div>
      </div>
    </div>
  )
}

export default RecallRockriderHelmet
